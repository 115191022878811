// BusLines.js
import React, { useEffect, useState, useRef, useLayoutEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import AlternatingAd from './AlternatingAd';
import './BusLines.css'; // Voir ci-dessous pour un exemple de CSS
import {
  Container,
  Row,
  Col,
  Modal,
  FormControl,
  Form,
  Placeholder,
  Spinner,
  Toast,
  ToastContainer,
  Button,
  Alert
} from 'react-bootstrap';

import { IoIosArrowForward } from "react-icons/io";
import { TiDelete } from "react-icons/ti";
import { FaSearch, FaStar, FaTimes } from "react-icons/fa";
import { FaCircleCheck, FaBusSimple } from "react-icons/fa6";
import { GiBusStop } from "react-icons/gi";
import Marquee from "react-fast-marquee";
import { FaChevronRight } from "react-icons/fa";
import { FaMapLocationDot } from "react-icons/fa6";
import { FaClock } from "react-icons/fa";

// Icônes pour les alertes
import { RiSnowyFill } from "react-icons/ri";
import { IoAlertCircle } from "react-icons/io5";
import { FaBurst } from "react-icons/fa6";

// Icônes pour les favoris
import { MdOutlineStar, MdOutlineStarBorderPurple500 } from "react-icons/md";

import { Helmet } from 'react-helmet';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './App.css';

// -----------------------
// Composant de Placeholder animé pour les lignes de bus
// -----------------------
const AnimatedBusLinePlaceholder = ({ count }) => {
  return (
    <div className="placeholder-container">
      {Array.from({ length: count }).map((_, index) => (
        <div key={index} className="placeholder-card animated-placeholder">
          <div className="placeholder-icon">
            <FaBusSimple size={24} />
          </div>
          <div className="placeholder-text">
            <Placeholder as="div" animation="glow">
              <Placeholder xs={6} />
            </Placeholder>
          </div>
        </div>
      ))}
    </div>
  );
};

// -----------------------
// Helper functions
// -----------------------
const isColorLight = (color) => {
  if (!color) return true;
  const c = color.startsWith('#') ? color.slice(1) : color;
  const rgb = parseInt(c, 16);
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = rgb & 0xff;
  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luma > 128;
};

const darkenColor = (hex, factor = 0.90) => {
  let c = hex.replace('#', '');
  if (c.length === 3) c = c.split('').map(ch => ch + ch).join('');
  let r = parseInt(c.substring(0, 2), 16);
  let g = parseInt(c.substring(2, 4), 16);
  let b = parseInt(c.substring(4, 6), 16);
  r = Math.floor(r * factor);
  g = Math.floor(g * factor);
  b = Math.floor(b * factor);
  const toHex = (n) => n.toString(16).padStart(2, '0');
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

// -----------------------
// Fonctions utilitaires pour le token utilisateur
// -----------------------
const generateToken = () => {
  return Math.random().toString(36).substr(2) + Date.now().toString(36);
};

const getUserToken = () => {
  let token = localStorage.getItem('userToken');
  if (!token) {
    token = generateToken();
    localStorage.setItem('userToken', token);
  }
  return token;
};

// -----------------------
// Composant LineNumber
// -----------------------
const LineNumber = ({ line, lineTextColor }) => {
  const text = line.route_short_name || "";
  const shouldScroll = text.length > 4;
  const darkBackground = darkenColor(`#${line.route_color}`, 0.90);
  const computedLineTextColor = isColorLight(darkBackground) ? 'black' : 'white';

  return (
    <div
      className="line-number"
      style={{
        backgroundColor: darkBackground,
        color: computedLineTextColor,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        minWidth: '65px',
        maxWidth: '65px',
        overflow: 'hidden',
        position: 'relative',
        padding: '5px 10px',
        textTransform: 'uppercase',
        borderRadius: '7px',
        fontWeight: 'bold',
        fontSize: '14px',
        marginRight: '5px',
        lineHeight: '1.2'
      }}
    >
      {line.route_type === 715 ? (
        <FaBusSimple style={{ color: lineTextColor }} />
      ) : shouldScroll ? (
        <div className="scrolling-text-wrapper">
          <div className="scrolling-text">{text}</div>
        </div>
      ) : (
        <span>{text}</span>
      )}
    </div>
  );
};

// -----------------------
// Nouveau composant FullScreenDirectionModal
// -----------------------
const FullScreenDirectionModal = ({
  show,
  onClose,
  line,
  directions,
  onSelectDirection
}) => {
  if (!show || !line) return null;
  const originalColor = line.route_color ? `#${line.route_color}` : '#000';
  const backgroundColor = darkenColor(originalColor, 0.90);
  const computedTextColor = isColorLight(backgroundColor) ? 'black' : 'white';
  const lineColor = darkenColor(originalColor, 0.90);

  return (
    <div className="direction-fullscreen-modal">
      <div
        className="direction-fullscreen-overlay"
        style={{ backgroundColor, zIndex: 10001 }}
      >
        <div className="direction-fullscreen-close" onClick={onClose}>
          <FaTimes />
        </div>
        <div className="direction-fullscreen-content" style={{ color: computedTextColor }}>
          <div
            style={{
              backgroundColor: 'white',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '20px',
              textAlign: 'center',
              minWidth: '85px',
              overflow: 'hidden',
              position: 'relative',
              padding: '5px 10px',
              textTransform: 'uppercase',
              borderRadius: '7px',
              fontWeight: 'bold',
              fontSize: '14px',
              marginRight: '5px',
              lineHeight: '1.2',
            }}
          >
            <span className="direction-line-name" style={{ color: lineColor }}>
              {line.route_short_name}
            </span>
          </div>
          <div className="direction-list-wrapper">
            {Object.entries(directions).map(([direction_id, terminus]) => (
              <div
                key={direction_id}
                className="direction-item"
                onClick={() => onSelectDirection(direction_id)}
              >
                <div className="direction-label" style={{ color: computedTextColor }}>
                  vers
                </div>
                <div className="direction-terminus" style={{ color: computedTextColor }}>
                  {terminus}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="direction-fullscreen-backdrop" onClick={onClose} style={{ zIndex: 10000 }}></div>
    </div>
  );
};

function BusLines() {
  const { networkId } = useParams();
  const navigate = useNavigate();

  // -----------------------
  // États principaux
  // -----------------------
 // États principaux
  const [advertisement, setAdvertisement] = useState(null);
  const [adLoading, setAdLoading] = useState(true);
  const [lines, setLines] = useState(null);
  const [favorites, setFavorites] = useState(null);
  const [loadingFavorites, setLoadingFavorites] = useState(true);
  const [networksList, setNetworksList] = useState([]);
  const [invalidNetwork, setInvalidNetwork] = useState(false);
  const [stops, setStops] = useState([]);
  const [busStopSearchTerm, setBusStopSearchTerm] = useState('');
  const [loadingStops, setLoadingStops] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedBox, setSelectedBox] = useState('');
  const [selectedLine, setSelectedLine] = useState(null);
  const [directions, setDirections] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [toasts, setToasts] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [loadingAlert, setLoadingAlert] = useState(true);
  const [currentAlertPopup, setCurrentAlertPopup] = useState(null);
  const [showStopSearchOverlay, setShowStopSearchOverlay] = useState(false);
  const [showAnnivModal, setShowAnnivModal] = useState(false);
  const [annivMessage, setAnnivMessage] = useState("");
  const [showAnnivSuccessModal, setShowAnnivSuccessModal] = useState(false);
  // Valeur par défaut pour le nombre de placeholders pour les lignes de bus
  const [expectedLineCount, setExpectedLineCount] = useState(8);

  // Déclaration du nombre attendu de placeholders pour les favoris
  const expectedFavoritesCount = favorites ? favorites.length : 2;

  const hexToRgba = (hex, alpha = 1) => {
    if (!hex) return `rgba(0, 0, 0, ${alpha})`;
    hex = hex.replace('#', '');
    if (hex.length === 3) hex = hex.split('').map(c => c + c).join('');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const hexToRgbArray = (hex) => {
    if (!hex) return [0, 0, 0];
    if (hex.startsWith('#')) hex = hex.slice(1);
    if (hex.length === 3) hex = hex.split('').map(c => c + c).join('');
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
  };

  // -----------------------
  // Fonctions pour gérer le modal et la sélection de direction
  // -----------------------
  const handleShowModal = (line) => {
    if (line.etat === 0) return;
    const lineWithNetwork = { ...line, network_id: line.network_id || networkId };
    setSelectedLine(lineWithNetwork);
    axios
      .get(`/getBusLines.php?route_id=${lineWithNetwork.route_id}&network_id=${lineWithNetwork.network_id}`, { timeout: 10000 })
      .then((response) => {
        const directionsData = response.data;
        if (directionsData && typeof directionsData === 'object' && Object.keys(directionsData).length === 1) {
          const directionId = Object.keys(directionsData)[0];
          navigate(`/schedule/${lineWithNetwork.network_id}/${lineWithNetwork.route_id}/${directionId}`);
        } else if (directionsData && Object.keys(directionsData).length > 1) {
          setDirections(directionsData);
          setShowModal(true);
        } else {
          console.warn('Aucune direction trouvée pour cette ligne.');
        }
      })
      .catch((error) => {
        console.error('Erreur lors de la récupération des directions!', error);
      });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedLine(null);
    setDirections([]);
  };

  const handleSelectDirection = (direction_id) => {
    if (selectedLine && selectedLine.etat === 1) {
      navigate(`/schedule/${selectedLine.network_id}/${selectedLine.route_id}/${direction_id}`);
    }
  };

  const legacyNetworks = {
    "DUC": {
      newId: "AIREMOB",
      customMessage: "Le réseau DUC devient « AIREMOB ». Cliquez sur le bouton ci-dessous pour accéder directement au réseau AIREMOB."
    },
    "LAM": {
      newId: "AIREMOB",
      customMessage: "Le réseau LA NAVETTE devient « AIREMOB ». Cliquez sur le bouton ci-dessous pour accéder directement au réseau AIREMOB."
    },
    "CCAC": {
      newId: "AIREBUS",
      customMessage: "Le réseau AIREBUS est fusionné avec « AIREMOB ». Cliquez sur le bouton pour y accéder directement."
    },
    "ratpdev_rio4": {
      newId: "RIO4",
      customMessage: "Le réseau RIO4 a changé d'identifiant. Cliquez sur le bouton pour y accéder directement."
    },
    "CYP": {
      newId: "MOBI",
      customMessage: "Le réseau MOBI a changé d'identifiant. Cliquez sur le bouton pour y accéder directement."
    },
    "TRIO": {
      newId: "TRIO1",
      customMessage: "Le réseau TRIO 1 a changé d'identifiant. Cliquez sur le bouton pour y accéder directement."
    }
  };

  const requestCacheRef = useRef(new Map());
  const [searchCache, setSearchCache] = useState(() => {
    const stored = localStorage.getItem('searchCache');
    if (stored) {
      try {
        return JSON.parse(stored);
      } catch {
        return {};
      }
    }
    return {};
  });
  const cancelTokenSourceRef = useRef(null);

  const isColorAlert = (color) => {
    if (!color) return true;
    let r, g, b;
    if (color.startsWith('#')) {
      let hex = color.slice(1);
      if (hex.length === 3) hex = hex.split('').map(c => c + c).join('');
      const intVal = parseInt(hex, 16);
      r = (intVal >> 16) & 255;
      g = (intVal >> 8) & 255;
      b = intVal & 255;
    } else if (color.startsWith('rgb')) {
      const rgbValues = color.match(/\d+/g);
      if (rgbValues && rgbValues.length >= 3) {
        r = parseInt(rgbValues[0], 10);
        g = parseInt(rgbValues[1], 10);
        b = parseInt(rgbValues[2], 10);
      }
    } else {
      const tempDiv = document.createElement("div");
      tempDiv.style.color = color;
      document.body.appendChild(tempDiv);
      const computedColor = getComputedStyle(tempDiv).color;
      document.body.removeChild(tempDiv);
      const rgbValues = computedColor.match(/\d+/g);
      if (rgbValues && rgbValues.length >= 3) {
        r = parseInt(rgbValues[0], 10);
        g = parseInt(rgbValues[1], 10);
        b = parseInt(rgbValues[2], 10);
      }
    }
    if (r === undefined || g === undefined || b === undefined) return true;
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luma > 128;
  };

  const getAgencyShortName = (network_id) => {
    const net = networksList.find((n) => n.network_id === network_id);
    if (net && net.agency_name) {
      return net.agency_name.split(' - ')[0];
    }
    return network_id;
  };

  // -----------------------
  // Gestion des favoris via PHP
  // -----------------------
  const userToken = getUserToken();

  const fetchFavorites = useCallback(async () => {
    try {
      const response = await axios.get(`/getFavorites.php?token=${userToken}`, { timeout: 10000 });
      if (Array.isArray(response.data)) {
        setFavorites(response.data);
      } else {
        console.error('Réponse inattendue pour les favoris :', response.data);
        setFavorites([]);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des favoris:', error);
      setFavorites([]);
    } finally {
      setLoadingFavorites(false);
    }
  }, [userToken]);

  const addFavoriteDB = async (lineObj) => {
    try {
      await axios.post(
        '/addFavorite.php',
        { token: userToken, favorite: lineObj },
        { timeout: 10000 }
      );
      setFavorites(prev => [...prev, lineObj]);
      const agencyShortName = getAgencyShortName(lineObj.network_id);
      addToast(
        <>
          La ligne {lineObj.route_short_name} (<strong>{agencyShortName}</strong>) a été ajoutée à vos favoris
        </>
      );
    } catch (error) {
      console.error("Erreur lors de l'ajout du favori:", error);
    }
  };

  const removeFavoriteDB = async (network_id, route_id) => {
    try {
      await axios.post(
        '/removeFavorite.php',
        { token: userToken, network_id, route_id },
        { timeout: 10000 }
      );
      setFavorites(prev => prev.filter(fav => !(fav.network_id === network_id && fav.route_id === route_id)));
      const agencyShortName = getAgencyShortName(network_id);
      addToast(
        <>
          La ligne {route_id} (<strong>{agencyShortName}</strong>) a été retirée de vos favoris
        </>
      );
    } catch (error) {
      console.error("Erreur lors de la suppression du favori:", error);
    }
  };

  const isFavorite = (lineObj) => {
    if (!favorites) return false;
    return favorites.some(
      fav => fav.network_id === lineObj.network_id && fav.route_id === lineObj.route_id
    );
  };

  const toggleFavorite = (lineObj) => {
    if (isFavorite(lineObj)) {
      removeFavoriteDB(lineObj.network_id, lineObj.route_id);
    } else {
      addFavoriteDB(lineObj);
    }
  };

  // -----------------------
  // Effets
  // -----------------------
  useEffect(() => {
    // Récupération des alertes
    const fetchAlerts = (isInitial = false) => {
      if (isInitial) setLoadingAlert(true);
      axios
        .get(`/getAlerts.php?network_id=${networkId}`, { timeout: 10000 })
        .then((response) => {
          if (Array.isArray(response.data)) {
            const sortedAlerts = response.data.sort((a, b) => b.id - a.id);
            setAlerts(prevAlerts => {
              if (
                prevAlerts.length === sortedAlerts.length &&
                sortedAlerts.every((alert, i) => alert.id === prevAlerts[i].id)
              ) {
                return prevAlerts;
              }
              return sortedAlerts;
            });
          } else if (response.data && response.data.id) {
            setAlerts(prevAlerts => {
              if (prevAlerts.length === 1 && prevAlerts[0].id === response.data.id) {
                return prevAlerts;
              }
              return [response.data];
            });
          } else {
            setAlerts([]);
          }
        })
        .catch((error) => {
          console.error('Erreur lors du chargement des alertes!', error);
          setAlerts([]);
        })
        .finally(() => {
          if (isInitial) setLoadingAlert(false);
        });
    };
    fetchAlerts(true);
    const intervalId = setInterval(() => {
      fetchAlerts(false);
    }, 10000);
    return () => clearInterval(intervalId);
  }, [networkId]);

  useEffect(() => {
    axios
      .get('/getNetworks.php', { timeout: 10000 })
      .then((response) => {
        if (Array.isArray(response.data)) {
          setNetworksList(response.data);
        } else {
          console.error('Expected an array but got:', response.data);
        }
      })
      .catch((error) => {
        console.error('Erreur lors du chargement des réseaux:', error);
      });
  }, []);

  useEffect(() => {
    if (networksList.length === 0) return;
    const foundNetwork = networksList.find((n) => n.network_id === networkId);
    if (!foundNetwork) {
      setInvalidNetwork(true);
    } else {
      setInvalidNetwork(false);
    }
  }, [networkId, networksList]);

  useEffect(() => {
    setAdLoading(true);
    axios
      .get('/publicites.php', { timeout: 10000 })
      .then((response) => {
        if (response.data && response.data.image) {
          setAdvertisement(response.data);
          axios.post('/update_stats.php', { ad_id: response.data.id }, { timeout: 10000 })
            .catch((error) => {
              console.error("Erreur lors de l'incrémentation des vues de pub", error);
            });
        } else {
          setAdvertisement(null);
        }
      })
      .catch((error) => {
        console.error("Erreur lors du chargement de la publicité", error);
        setAdvertisement(null);
      })
      .finally(() => {
        setAdLoading(false);
      });
  }, [networkId]);

  useEffect(() => {
    const cacheKey = `busLines?network_id=${networkId}`;
    if (invalidNetwork) {
      setLines([]);
      return;
    }
    if (requestCacheRef.current.has(cacheKey)) {
      setLines(requestCacheRef.current.get(cacheKey));
      setExpectedLineCount(requestCacheRef.current.get(cacheKey).length);
      return;
    }
    axios
      .get(`/getBusLines.php?network_id=${networkId}`, { timeout: 10000 })
      .then((response) => {
        if (Array.isArray(response.data)) {
          setLines(response.data);
          setExpectedLineCount(response.data.length);
          requestCacheRef.current.set(cacheKey, response.data);
        } else {
          console.error('Réponse API non attendue:', response.data);
          setLines([]);
        }
      })
      .catch((error) => {
        console.error('Erreur lors de la récupération des lignes de bus!', error);
        setLines([]);
      });
  }, [networkId, invalidNetwork]);

  useEffect(() => {
    fetchFavorites();
  }, [fetchFavorites]);

  const updateSearchCache = useCallback((key, arrStops) => {
    const newCache = { ...searchCache };
    newCache[key] = arrStops;
    setSearchCache(newCache);
    localStorage.setItem('searchCache', JSON.stringify(newCache));
  }, [searchCache]);

  useEffect(() => {
    if (!showStopSearchOverlay) return;
    if (busStopSearchTerm.trim().length === 0) {
      setStops([]);
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      setLoadingStops(true);
      const typedLower = busStopSearchTerm.trim().toLowerCase();
      const typedKeyPrefix = `${networkId}_${typedLower}`;
      const partialMatches = Object.keys(searchCache).filter((key) =>
        key.startsWith(typedKeyPrefix)
      );
      if (partialMatches.length > 0) {
        const mergedStops = new Set();
        partialMatches.forEach((k) => {
          const arr = searchCache[k];
          arr.forEach((s) => mergedStops.add(JSON.stringify(s)));
        });
        const mergedArray = Array.from(mergedStops).map((s) => JSON.parse(s));
        setStops(mergedArray);
        setLoadingStops(false);
      } else {
        if (cancelTokenSourceRef.current) {
          cancelTokenSourceRef.current.cancel('Nouvelle requête');
        }
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        cancelTokenSourceRef.current = source;
        axios
          .get(
            `/Arrets.php?network_id=${encodeURIComponent(networkId)}&searchTerm=${encodeURIComponent(busStopSearchTerm)}`,
            { cancelToken: source.token, timeout: 10000 }
          )
          .then((response) => {
            if (Array.isArray(response.data)) {
              updateSearchCache(typedKeyPrefix, response.data);
              setStops(response.data);
              setLoadingStops(false);
            } else {
              console.error('Réponse inattendue pour les arrêts:', response.data);
              setStops([]);
              setLoadingStops(false);
            }
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              console.log('Requête annulée (nouvelle saisie).');
            } else {
              console.error('Erreur lors de la récupération des arrêts:', error);
              setStops([]);
              setLoadingStops(false);
            }
          });
      }
    }, 400);
    return () => clearTimeout(delayDebounceFn);
  }, [busStopSearchTerm, networkId, searchCache, showStopSearchOverlay, updateSearchCache]);

  // -----------------------
  // Affichage des alertes (ne pas toucher)
  // -----------------------
  const AlertBar = ({ alert, onClickAlert }) => {
    const textContainerRef = useRef(null);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const checkOverflow = () => {
      if (textContainerRef.current) {
        setIsOverflowing(textContainerRef.current.scrollWidth > textContainerRef.current.clientWidth);
      }
    };
    useLayoutEffect(() => { checkOverflow(); }, [alert.title]);
    useEffect(() => {
      window.addEventListener('resize', checkOverflow);
      return () => window.removeEventListener('resize', checkOverflow);
    }, []);
    const renderAlertIcon = () => {
      const iconColor = isColorAlert(alert.color) ? 'black' : 'white';
      const iconeValue = String(alert.icone);
      if (iconeValue === '1') { return <RiSnowyFill style={{ fontSize: '28px', color: iconColor }} />; }
      if (iconeValue === '2') { return <IoAlertCircle style={{ fontSize: '28px', color: iconColor }} />; }
      if (iconeValue === '3') { return <FaBurst style={{ fontSize: '28px', color: iconColor }} />; }
      return null;
    };
    return (
      <div
        onClick={() => onClickAlert(alert)}
        style={{
          backgroundColor: alert.color,
          color: isColorAlert(alert.color) ? 'black' : 'white',
          width: '100%',
          padding: '10px 20px',
          marginTop: '-6px',
          marginBottom: '5px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', overflow: 'hidden', flex: 1 }}>
          <div style={{ flexShrink: 0 }}>{renderAlertIcon()}</div>
          <div ref={textContainerRef} style={{ flex: 1, overflow: 'hidden', whiteSpace: 'nowrap', position: 'relative' }}>
            {isOverflowing ? (
              <>
                <Marquee gradient={false} speed={50} pauseOnHover={true} style={{ whiteSpace: 'nowrap', pointerEvents: 'none', zIndex: 1 }}>
                  <span style={{ fontSize: '1rem', fontWeight: 'bold', textTransform: 'uppercase', paddingRight: '50px' }}>
                    {alert.title}
                  </span>
                </Marquee>
                <div style={{ position: 'absolute', left: 0, top: 0, bottom: 0, width: '50px', pointerEvents: 'none', background: `linear-gradient(to right, ${alert.color}, transparent)`, zIndex: 2 }} />
                <div style={{ position: 'absolute', right: 0, top: 0, bottom: 0, width: '50px', pointerEvents: 'none', background: `linear-gradient(to left, ${alert.color}, transparent)`, zIndex: 2 }} />
              </>
            ) : (
              <span style={{ fontSize: '1rem', fontWeight: 'bold', textTransform: 'uppercase' }}>
                {alert.title}
              </span>
            )}
          </div>
        </div>
        <IoIosArrowForward style={{ fontSize: '1.5rem', color: isColorAlert(alert.color) ? 'black' : 'white' }} />
      </div>
    );
  };

  const AlertPopup = ({ alert, onClose }) => {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.8)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 20000,
          padding: '20px'
        }}
      >
        <div style={{ position: 'absolute', top: '20px', right: '20px', cursor: 'pointer', color: 'white', fontSize: '30px' }} onClick={onClose}>
          <FaTimes />
        </div>
        <div
          style={{
            backgroundColor: alert.color,
            padding: '30px',
            borderRadius: '10px',
            maxWidth: '600px',
            width: '100%',
            maxHeight: '80%',
            overflowY: 'auto',
            boxShadow: '0 4px 12px rgba(0,0,0,0.5)',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div style={{ alignSelf: 'flex-start' }}>
            {(() => {
              const iconColor = isColorAlert(alert.color) ? 'black' : 'white';
              const iconeValue = String(alert.icone);
              if (iconeValue === '1') { return <RiSnowyFill style={{ fontSize: '40px', color: iconColor }} />; }
              if (iconeValue === '2') { return <IoAlertCircle style={{ fontSize: '40px', color: iconColor }} />; }
              if (iconeValue === '3') { return <FaBurst style={{ fontSize: '40px', color: iconColor }} />; }
              return null;
            })()}
          </div>
          <div style={{ textAlign: 'center', fontSize: '1.3rem', fontWeight: 'bold', marginTop: '10px', marginBottom: '20px', color: isColorAlert(alert.color) ? 'black' : 'white' }}>
            {alert.title}
          </div>
          <div style={{ textAlign: 'center', fontSize: '0.8rem', fontWeight: 'bold', color: isColorAlert(alert.color) ? 'black' : 'white' }}>
            {alert.message}
          </div>
        </div>
      </div>
    );
  };

  // -----------------------
  // Bloc Réseau Hérité
  // -----------------------
  if (invalidNetwork) {
    const legacyData = legacyNetworks[networkId];
    if (legacyData) {
      return (
        <Container style={{ marginTop: '20px' }}>
          <Alert variant="warning" style={{ textAlign: 'center' }}>
            <Alert.Heading>
              Ancien réseau : <strong>{networkId}</strong>
            </Alert.Heading>
            <p style={{ marginTop: '10px' }}>
              {legacyData.customMessage}
            </p>
            <hr />
            <div>
              <Button
                variant="primary"
                onClick={() => {
                  Cookies.set('selectedNetwork', legacyData.newId, { expires: 365 });
                  requestCacheRef.current.delete(`busLines?network_id=${networkId}`);
                  navigate(`/network/${legacyData.newId}`, { replace: true });
                }}
              >
                Allez sur le réseau {legacyData.newId}
              </Button>
            </div>
          </Alert>
        </Container>
      );
    } else {
      return (
        <Container style={{ marginTop: '20px' }}>
          <Alert variant="danger" style={{ textAlign: 'center' }}>
            <Alert.Heading>Oups ! Ce réseau n'existe pas ou plus.</Alert.Heading>
            <p>
              Il est possible que vous ayez suivi un lien obsolète ou saisi vous-même l’URL du réseau de bus.
            </p>
            <hr />
            <div>
              <Button variant="primary" onClick={() => navigate('/change-network')}>
                Changer de réseau
              </Button>
            </div>
          </Alert>
        </Container>
      );
    }
  }

  const handleAnnivSubmit = () => {
    console.log("Message envoyé :", annivMessage);
    if (annivMessage.trim() === "") {
      alert("Message vide");
      return;
    }
    const params = new URLSearchParams();
    params.append("message", annivMessage);
    axios
      .post('/anniversaire.php', params, { timeout: 10000 })
      .then((response) => {
        console.log("Réponse du serveur :", response.data);
        if (response.data.success) {
          setAnnivMessage("");
          setShowAnnivModal(false);
          setShowAnnivSuccessModal(true);
        } else {
          alert("Erreur : " + response.data.error);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de l'envoi du message :", error);
        alert("Une erreur est survenue lors de l'envoi du message.");
      });
  };

  const addToast = (message) => {
    const id = Date.now();
    setToasts(prev => [...prev, { id, message, visible: true }]);
  };

  const handleToastClose = (id) => {
    setToasts(prev =>
      prev.map(t => t.id === id ? { ...t, visible: false } : t)
    );
    setTimeout(() => {
      setToasts(prev => prev.filter(t => t.id !== id));
    }, 500);
  };

  // -----------------------
  // Filtrage local des lignes et favoris
  // -----------------------
  const filteredLines = Array.isArray(lines)
    ? lines.filter(
        (line) =>
          (line.route_short_name?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
          (line.route_long_name?.toLowerCase() || '').includes(searchTerm.toLowerCase())
      )
    : [];
    
  const filteredFavorites = Array.isArray(favorites)
    ? favorites.filter(
        (fav) =>
          (fav.route_short_name?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
          (fav.route_long_name?.toLowerCase() || '').includes(searchTerm.toLowerCase())
      )
    : [];
  const reversedFavorites = [...filteredFavorites].reverse();

  return (
    <div>
      <Helmet>
        <title>Bus Connect - {networksList.find(n => n.network_id === networkId)?.agency_name || 'Inconnu'}</title>
      </Helmet>

      {/* Affichage des alertes */}
      {!loadingAlert && alerts.length > 0 && (
        <TransitionGroup>
          {alerts.map(alert => (
            <CSSTransition key={alert.id} timeout={500} classNames="alert">
              <AlertBar alert={alert} onClickAlert={(alert) => setCurrentAlertPopup(alert)} />
            </CSSTransition>
          ))}
        </TransitionGroup>
      )}

      {currentAlertPopup && (
        <AlertPopup alert={currentAlertPopup} onClose={() => setCurrentAlertPopup(null)} />
      )}

      <Container className="my-1 d-flex align-items-center justify-content-center" style={{ marginTop: alerts.length > 0 ? '20px' : 0 }}>
        <div className="w-100">
          <AlternatingAd advertisement={advertisement} adLoading={adLoading} />

          {/* Bloc Favoris */}
          <div className="favorites-wrapper mb-4">
            <h5 className="section-title">
              <FaStar /> <span>Vos lignes favorites</span>
            </h5>
            {loadingFavorites ? (
              <div className="favorites-placeholder" style={{ display: 'flex', gap: '10px' }}>
                {[...Array(expectedFavoritesCount)].map((_, i) => (
                  <div key={i} className="favorite-card placeholder-card">
                    <Placeholder as="span" animation="glow">
                      <Placeholder xs={6} />
                    </Placeholder>
                  </div>
                ))}
              </div>
            ) : reversedFavorites.length > 0 ? (
              <div className="favorites-container">
                {reversedFavorites.map((favLine) => {
                  const darkBackground = darkenColor(`#${favLine.route_color}`, 0.90);
                  const computedTextColor = isColorLight(darkBackground) ? 'black' : 'white';
                  const agencyShortName = getAgencyShortName(favLine.network_id);
                  return (
                    <div
                      key={`${favLine.network_id}-${favLine.route_id}`}
                      className="favorite-card responsive-card"
                      style={{
                        backgroundColor: darkBackground,
                        color: computedTextColor,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: 'fit-content',
                        padding: '10px 20px',
                        borderRadius: '12px',
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                        cursor: 'pointer',
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                      }}
                      onClick={(e) => {
                        if (e.target.closest('.delete-icon')) return;
                        const lineObj = {
                          route_id: favLine.route_id,
                          route_short_name: favLine.route_short_name,
                          route_long_name: favLine.route_long_name,
                          route_color: favLine.route_color,
                          network_id: favLine.network_id,
                          etat: favLine.etat !== undefined ? favLine.etat : 1,
                        };
                        handleShowModal(lineObj);
                      }}
                      onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
                      onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                    >
                      <div className="fav-info" style={{ marginRight: '15px' }}>
                        <strong>{favLine.route_short_name}</strong> ({agencyShortName})
                      </div>
                      <div
                        className="delete-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeFavoriteDB(favLine.network_id, favLine.route_id);
                        }}
                        title="Supprimer ce favori"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: 'rgba(255, 255, 255, 0.7)',
                          borderRadius: '50%',
                          padding: '5px',
                          cursor: 'pointer',
                          transition: 'background-color 0.3s ease',
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.9)')
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.7)')
                        }
                      >
                        <TiDelete style={{ fontSize: '20px', color: 'black' }} />
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="no-favorites">Aucune ligne en favoris</div>
            )}
          </div>

          {/* Conteneur des boutons */}
          <div className="d-flex align-items-center gap-3">
            <div
              className={`search-box left ${selectedBox === 'line' ? 'active' : ''}`}
              onClick={() => {
                if (selectedBox === 'line') {
                  setSelectedBox('');
                } else {
                  setSelectedBox('line');
                  setShowStopSearchOverlay(false);
                }
              }}
            >
              <FaSearch className="search-icon" style={{ fontSize: '2em' }} /> 
            </div>

            <div
              className={`search-box right ${selectedBox === 'stop' ? 'active' : ''}`}
              onClick={() => {
                setSelectedBox('stop');
                navigate(`/network/${networkId}/map`);
              }}
            >
              <FaMapLocationDot className="search-icon" style={{ fontSize: '2em' }}  /> 
              <div className="badge-new">Plan</div>
            </div>

            <div
              className={`search-box third ${selectedBox === 'third' ? 'active' : ''}`}
              onClick={() => { 
                setSelectedBox('third'); 
                setShowStopSearchOverlay(true); 
              }}
            >
              <FaClock className="search-icon" style={{ fontSize: '2em' }} /> 
              <div className="badge-new">Recherche arrêt</div>
            </div>
          </div>

          {selectedBox === 'line' && (
            <Row className="mb-4">
              <Col>
                <FormControl
                  type="text"
                  placeholder="Filtrer les lignes..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="search-inputs"
                />
              </Col>
            </Row>
          )}

          {/* Liste des lignes */}
          <Row className="lines-row">
            <Col>
              {lines === null ? (
                // Affiche autant de placeholders que de lignes attendues
                <AnimatedBusLinePlaceholder count={expectedLineCount} />
              ) : (
                <>
                  <ul className="lines-list" style={{ border: 'none', listStyle: 'none', padding: 0 }}>
                    {filteredLines.map((line) => {
                      const lineTextColor = isColorLight(line.route_color) ? 'black' : 'white';
                      const lineStyle = line.etat === 0
                        ? { opacity: '0.3', pointerEvents: 'none' }
                        : {};
                      const lineObj = {
                        route_id: line.route_id,
                        route_short_name: line.route_short_name,
                        route_long_name: line.route_long_name,
                        route_color: line.route_color,
                        network_id: line.network_id || networkId,
                        etat: line.etat,
                        route_type: line.route_type
                      };

                      const [r, g, b] = hexToRgbArray(line.route_color);
                      const colorTranslucent = `rgba(${r}, ${g}, ${b}, 0.4)`;
                      const darkBackground = darkenColor(`#${line.route_color}`, 0.90);

                      return (
                        <li
                          key={line.route_id}
                          className="line-card"
                          onClick={() => handleShowModal(lineObj)}
                          style={{
                            ...lineStyle,
                            '--lineColorSolid': darkBackground,
                            '--lineColorTranslucent': colorTranslucent,
                            boxShadow: `5px 5px 20px ${hexToRgba(line.route_color, 0.11)}`,
                            border: 'none'
                          }}
                        >
                          <div className="line-content">
                            <div className="line-info">
                              <div
                                className="favorite-toggle"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleFavorite(lineObj);
                                }}
                                title={isFavorite(lineObj) ? 'Retirer des favoris' : 'Ajouter aux favoris'}
                              >
                                {isFavorite(lineObj) ? (
                                  <MdOutlineStar style={{ color: '#0a78a4', fontSize: '30px' }} />
                                ) : (
                                  <MdOutlineStarBorderPurple500 style={{ color: '#555', fontSize: '30px' }} />
                                )}
                              </div>

                              <LineNumber line={lineObj} lineTextColor={lineTextColor} />

                              <div className="line-name-text">
                                {line.route_long_name}
                              </div>
                            </div>
                            <div className="arrow-icon">
                              <IoIosArrowForward />
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  {searchTerm.length > 0 && filteredLines.length === 0 && (
                    <div className="no-lines">
                      Aucune ligne ne correspond à votre recherche.
                    </div>
                  )}
                </>
              )}
            </Col>
          </Row>

          {/* Toast Favoris */}
          <ToastContainer className="toast-fixed">
            {toasts.map((toast) => (
              <Toast
                key={toast.id}
                onClose={() => handleToastClose(toast.id)}
                delay={5000}
                autohide
                show={toast.visible}
                className={toast.visible ? "toast-item" : "toast-item closing"}
              >
                <Toast.Header>
                  <FaCircleCheck style={{ color: 'green', marginRight: '8px' }} />
                  <strong className="me-auto">Favoris mis à jour</strong>
                </Toast.Header>
                <Toast.Body>{toast.message}</Toast.Body>
              </Toast>
            ))}
          </ToastContainer>
        </div>
      </Container>

      {/* Overlay pour la recherche d'arrêts */}
      {showStopSearchOverlay && (
        <div className="stop-overlay">
          <div className="stop-overlay-header">
            <FaTimes className="overlay-close" onClick={() => setShowStopSearchOverlay(false)} />
          </div>
          <div className="stop-overlay-body">
            <FormControl
              type="text"
              placeholder="Rechercher votre arrêt..."
              value={busStopSearchTerm}
              onChange={(e) => setBusStopSearchTerm(e.target.value)}
            />
            {loadingStops && (
              <div className="loading-stops">
                <Spinner animation="border" role="status" size="sm">
                  <span className="visually-hidden">Chargement...</span>
                </Spinner>
                <span>Recherche en cours...</span>
              </div>
            )}
            <div className="stops-container">
              {stops.map((stop) => (
                <div
                  key={stop.stop_id}
                  className="stop-item"
                  onClick={() => {
                    setShowStopSearchOverlay(false);
                    navigate(`/network/${networkId}/stop/${stop.stop_id}`);
                  }}
                >
                  <div className="stop-info">
                    <GiBusStop className="stop-icon" />
                    <div>
                      <div className="stop-name">{stop.stop_name}</div>
                      {stop.city && <div className="stop-city">{stop.city}</div>}
                    </div>
                  </div>
                  <FaChevronRight className="stop-arrow" />
                </div>
              ))}
              {(!loadingStops && busStopSearchTerm.trim().length > 0 && stops.length === 0) && (
                <div className="no-stops">
                  Aucun arrêt ne correspond à votre recherche.
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Nouvelle modal fullscreen pour les directions */}
      <FullScreenDirectionModal
        show={showModal}
        onClose={handleCloseModal}
        line={selectedLine}
        directions={directions}
        onSelectDirection={handleSelectDirection}
      />
    </div>
  );
}

export default BusLines;
