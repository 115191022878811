// App.js
import React, { useEffect } from 'react';
import axios from 'axios';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation
} from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga4';
import ScrollToTop from './ScrollToTop';

// Composants globaux
import Header from './Header';
import Footer from './Footer';

// Pages de l'application
import Accueil from './Accueil';
import Reseau from './Reseau';
import BusLines from './BusLines';
import Schedule from './Schedule';
import AlertDetails from './AlertDetails';
import Arrets from './Arrets';
import StopDepartures from './StopDepartures';
import Infotrafic from './Infotrafic';
import Mentions from './Mentions';
import PrivacyPolicy from './PrivacyPolicy';
import Sondage from './Sondage';
import Update from './Update';
import FAQ from './FAQ';
import AccessibilityPage from './AccessibilityPage';
import Tohm from './blogs/tohm';
import Axo from './blogs/axo';
import Corolis from './blogs/corolis';
import Airemob from './blogs/airemob';
import Tic from './blogs/tic';
import Lebus from './blogs/lebus';
import StopsMap from './StopsMap';

/* ------------------------------------------------------------------
   Pensez à remplacer 'G-XXXXXXXXXX' par votre identifiant GA4
   ------------------------------------------------------------------ */
ReactGA.initialize('G-3XW783VZ82');

const App = () => {
  const [networks, setNetworks] = React.useState([]);
  const [selectedNetwork, setSelectedNetwork] = React.useState(Cookies.get('selectedNetwork') || '');
  const [loading, setLoading] = React.useState(true);
  const [showTutorial, setShowTutorial] = React.useState(false);

  const location = useLocation();

  // Intégration du script Crisp
  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "3a44d7b4-5b64-4d68-9bb7-32b3da85b2cc";
    (function() {
      const d = document;
      const s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = true;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  }, []);

  // Enregistrement de la pageview à chaque changement de route
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);

  // Récupération des réseaux
  useEffect(() => {
    axios.get('/getDepartments.php')
      .then(response => {
        if (Array.isArray(response.data)) {
          setNetworks(response.data);
        } else {
          console.error('Expected an array but got:', response.data);
        }
      })
      .catch(error => {
        if (String(error).includes("Primary script unknown")) {
          Cookies.remove('selectedNetwork');
        }
        console.error('Error fetching networks:', error);
      })
      .finally(() => setLoading(false));
  }, []);

  // Afficher le tutoriel si c'est la première sélection d'un réseau
  useEffect(() => {
    if (selectedNetwork && !Cookies.get('tutorialShown')) {
      setShowTutorial(true);
    }
  }, [selectedNetwork]);

  // Masquer le Header sur certaines pages (ex. /schedule)
  const hideHeader = location.pathname.startsWith('/schedule') || location.pathname === '/' || location.pathname === '/accueil';


  if (loading) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#fff'
      }}>
        <Spinner animation="border" role="status" style={{ color: '#0a78a4' }}>
          <span className="visually-hidden">Chargement...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <>
      {!hideHeader && (
        <Header
          selectedNetwork={selectedNetwork}
          networks={networks}
          showTutorial={showTutorial}
          setShowTutorial={setShowTutorial}
        />
      )}

      <Routes>
        {/* Si un réseau est sélectionné, redirige automatiquement de "/" vers "/network/:networkId" */}
        <Route path="/" element={
          selectedNetwork ? <Navigate to={`/network/${selectedNetwork}`} replace /> : <Accueil />
        }/>
        <Route path="/reseau" element={<Reseau networks={networks} setSelectedNetwork={setSelectedNetwork} />} />
        <Route path="/network/:networkId" element={<BusLines />} />
        <Route path="/schedule/:networkId/:routeId/:directionId" element={<Schedule />} />
        <Route path="/network/:networkId/alert/:alertId" element={<AlertDetails />} />
        <Route path="/arrets" element={<Arrets />} />
        <Route path="/network/:networkId/stop/:stopId" element={<StopDepartures />} />
        <Route path="/infotrafic" element={<Infotrafic />} />
        <Route path="/mentions" element={<Mentions />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/sondage" element={<Sondage />} />
        <Route path="/update" element={<Update />} />
        <Route path="/FAQ" element={<FAQ />} />
        <Route path="/AccessibilityPage" element={<AccessibilityPage />} />
        <Route path="/blogs/tohm" element={<Tohm />} />
        <Route path="/blogs/axo" element={<Axo />} />
        <Route path="/blogs/corolis" element={<Corolis />} />
        <Route path="/blogs/airemob" element={<Airemob />} />
        <Route path="/blogs/tic" element={<Tic />} />
        <Route path="/blogs/lebus" element={<Lebus />} />
        <Route path="/network/:networkId/map" element={<StopsMap />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>

      <Footer />
    </>
  );
};

const AppWrapper = () => (
  <Router>
    <ScrollToTop />
    <App />
  </Router>
);

export default AppWrapper;
